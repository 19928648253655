<template>
  <div>
    <main-nav></main-nav>
    <sidebar></sidebar>
    <router-view :key="$route.path"></router-view>
    <audio controls ref="notificationSound" class="soc_test select-none">
        <source src="@/assets/sounds/message_sent2.wav" type="audio/wav" />
    </audio>
  </div>
</template>

<script>
import MainNav from "../components/Layouts/MainNav.vue";
import Sidebar from "../components/Layouts/Sidebar.vue";
import io from "socket.io-client";

export default {
  name: "Home",

  components: { MainNav, Sidebar },

  data() {
    return {
      isLoading: true,
    };
  },

  methods: {
    launch_resize_dispatch() {
      this.$store.dispatch("sideNav_module/onResize");
    },
    allChatSoket() {
      const _self = this;
      // socket.off(`notification:${localStorage.getItem("shams_dash_User_Id")}`);
      var socket = io("https://api.shamspharmacy.com:6001");
      // console.log(socket);
      // var audio = document.createElement("AUDIO");
      // document.body.appendChild(audio);
      // audio.src = require("@/assets/sounds/message_sent2.wav");
      socket.off(`notification:${localStorage.getItem("shams_dash_User_Id")}`);
      socket.on(
        `notification:${localStorage.getItem("shams_dash_User_Id")}`,
        (payload) => {
          console.log(payload);
          if (payload) {
            // this.setMessage(payload);
            console.log(payload);
            // audio.play();
            let dataToPush = {
              data_id: payload.data_id,
              body: payload.body,
              title: payload.title,
            };

            _self.$store.commit(
              "notifications_module/pushNotification",
              dataToPush
            );
            this.$iziToast.show({
              timeout: 2000,
              message: payload.body,
              position: "bottomRight",
            });
            this.$refs.notificationSound.play();
          }
        }
      );
      // socket.off(`notification:${localStorage.getItem("shams_dash_User_Id")}`);
    },
  },

  mounted() {
    this.$store.dispatch("notifications_module/getNotifications");
    this.$store.dispatch("notifications_module/getUnRead");
    this.allChatSoket();
    // ===> check if the page is login
    this.launch_resize_dispatch();
    if (this.$store.getters.resizeEventExisit == false) {
      window.addEventListener("resize", this.launch_resize_dispatch, true);
      this.$store.commit("changeRessizeEvent");
    }
  },
};
</script>
