import axios from "axios";

export default {
  getNotifications(context) {
    axios
      .get("https://api.shamspharmacy.com/api/dashboard/notifications")
      .then((res) => {
        context.commit("setNotifications", res.data.data);
        context.commit("setUnread", res.data.unread_count);
      });
  },
  getUnRead(context) {
    axios
      .get(
        "https://api.shamspharmacy.com/api/dashboard/unread-notification-count"
      )
      .then((res) => {
        context.commit("setUnreadMes", res.data.data.unread);
      });
  },
};
